
.Apply {
  width: 756px;
  height: 432px;
  // width: 66%;
  // display: flex;
  // justify-content: center;
  .applyContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // margin-top: 40px;
    // margin-bottom: 80px;
    background: #ffffff;
    // box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5);
    // border-radius: 20px;
    // filter: blur(0px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // padding: 20px;
    // grid-gap: 24px 28px;
  }
}
.student {
  // width: 299px;
  // height: 82px;
  display: flex;
  width: 252px;
  height: 68px;
  background: #ffffff;
  // border-radius: 8px;
  // border: 1px solid #979797;
  filter: blur(0px);
  .studentInfo {
    padding: 16px 26px;
    display: flex;
    align-items: center;
    .ava {
      width: 44px;
      height: 44px;
      background: #FFFFFF;
      border-radius: 50%;
      text-align: center;
      line-height: 44px;
      
    }
    .text {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      > p {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 500;
        color: #101010;
      }
      > p ~ p {
        width: 152px;
        height: 12px;
        margin-top: 8px;
        color: #999999;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
      }
    }
  }
}
.student > p {
  /* width: 30%; */
  opacity: 0.5;
}

.studentInfo > img {
  width: 2%;
  min-width: 40px;
  border-radius: 50%;
  margin-right: 15px;
}


@media screen and (max-width: 768px) {
}
